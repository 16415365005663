import React, { useEffect, useState, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Row, Col, Card, CardBody, CardTitle, Modal, ModalBody, Label } from 'reactstrap';
import { del, get, post, put, upload } from '../../helper/api_helper';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Resizer from "react-image-file-resizer";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import * as Utils from "../../Utils";


function AddForm(props) {
    const { setIsAdd, user, currentData, loading, setLoading, getData, langs } = props;
    const [cropperModal, setCropperModal] = useState(false);
    const [gallery, setGallery] = useState([]);
    const [file, setFile] = useState(null);
    const [fileType, setFileType] = useState("image");
    const [image, setImage] = useState(null);
    const imagePickerRef = useRef();
    const cropperRef = useRef();
    const [bulletPoints, setBulletPoints] = useState([{}, {}, {}, {}, {}, {}, {}, {}, {}]);

    useEffect(() => {
        if (currentData?.bullets?.length)
            setBulletPoints(currentData?.bullets);
    }, [currentData])

    const handleImgChange = (file) => {
        setFile(URL.createObjectURL(file));
        setCropperModal(true);
    }

    const cropImage = () => {
        setLoading(true);
        const fileData = cropperRef?.current?.cropper?.getCroppedCanvas().toDataURL();
        if (fileType == "image") {
            setImage(fileData);
        } else {
            let temp = Object.assign([], gallery);
            temp.push(fileData);
            setGallery(temp);
        }
        setCropperModal(false);
        setLoading(false);
    }

    const handleValidSubmit = async (e, v) => {
        if (!loading) {
            setLoading(true);
            let body = {
                ...v,
                bullets: bulletPoints,
                token: user?.token
            }
            if (currentData?._id) {
                body = { ...body, planId: currentData?._id }
                put("plan", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            setIsAdd(false);
                            getData()
                        } else
                            toast.error("" + res?.error);
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("error while updating data", err);
                        toast.error("Something Went Wrong!");
                    })
            } else {
                post("plan", body)
                    .then(res => {
                        setLoading(false);
                        if (res?.statusCode == 200) {
                            toast.success(res?.message);
                            setIsAdd(false);
                            getData();
                        } else {
                            toast.error(res?.error);
                        }
                    })
                    .catch(err => {
                        setLoading(false);
                        console.error("Error while adding city", err);
                        toast.error("Something Went Wrong!");
                    })
            }
        }
    }

    const handleBulletInput = (val, index) => {
        let temp = Object.assign([], bulletPoints);
        temp[index] = val;
        setBulletPoints(temp);
    }

    const urltoFile = (url, filename, mimeType) => {
        return (fetch(url)
            .then(function (res) { return res.arrayBuffer(); })
            .then(function (buf) { return new File([buf], filename, { type: mimeType }); })
        );
    }

    const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1800,
                600,
                "webp",
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                "file"
            );
        });

    console.log("updateing plan", currentData, bulletPoints);

    return (
        <React.Fragment>
            <div className="main main-app p-3 p-lg-4">
                <Modal isOpen={cropperModal} className='homebanner-crop' centered={true}>
                    <ModalBody>
                        <h5 className="text-black font-size-20">Crop Image</h5>
                        <div className="py-2">
                            <AvForm
                                className="form-horizontal"
                                onValidSubmit={(e, v) => {
                                    cropImage()
                                }}
                            >
                                <div className="mb-3 mt-2">
                                    <Cropper
                                        style={{ height: 200, width: '100%' }}
                                        aspectRatio={fileType == "image" ? 1462 / 525 : 2 / 1}
                                        preview=".img-preview"
                                        guides={true}
                                        src={file}
                                        ref={cropperRef}
                                    />
                                </div>
                                <div className="mt-4">
                                    <Row>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                style={{ border: 'none', backgroundColor: Utils.themeColor }}
                                                onClick={() => { setCropperModal(false); setFile(null) }}
                                                type="reset"
                                            >
                                                Cancel
                                            </button>
                                        </Col>
                                        <Col md={6}>
                                            <button
                                                className="btn btn-primary w-100 waves-effect waves-light"
                                                type="submit"
                                                style={{ border: 'none', backgroundColor: Utils.themeColor }}
                                            >
                                                Submit
                                            </button>
                                        </Col>
                                    </Row>
                                </div>
                            </AvForm>
                        </div>
                    </ModalBody>
                </Modal>
                <input
                    name="image"
                    label="image"
                    className="d-none"
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleImgChange(e.target.files[0])}
                    ref={imagePickerRef}
                />
                <Card>
                    <CardBody>
                        <Row>
                            <Col md={10}>
                                <CardTitle className='mb-4'><b>{currentData?._id ? 'Update plan' : 'Add New plan'}</b></CardTitle>
                            </Col>
                            <Col>
                                <Button type="button" onClick={() => setIsAdd(false)} className="btn-sign" style={{ float: 'right' }}>Close</Button>
                            </Col>
                        </Row>
                        <AvForm onValidSubmit={handleValidSubmit}>
                            <Row>
                                <Col md={6}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="title"
                                            label="Title *"
                                            placeholder="Enter plan title"
                                            value={currentData?.title}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="tagLine"
                                            label="Tag Line *"
                                            placeholder="Enter plan tag line"
                                            value={currentData?.tagLine}
                                            required
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="amount"
                                            label="Amount *"
                                            placeholder="Enter plan amount"
                                            value={currentData?.amount}
                                            required
                                            type={"number"}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="gst"
                                            label="GST *"
                                            placeholder="Enter GST in %"
                                            value={currentData?.gst}
                                            required
                                            type={"number"}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="hCharge"
                                            label="Handling Charges *"
                                            placeholder="Enter handling charges"
                                            value={currentData?.hCharge}
                                            required
                                            type={"number"}
                                        />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className='mb-4'>
                                        <AvField
                                            name="perPage"
                                            label="Per Page Rate *"
                                            placeholder="Enter per page rate"
                                            value={currentData?.perPage}
                                            required
                                            type={"number"}
                                        />
                                    </div>
                                </Col>
                                <h5 className='mb-4'><b>Bullet Points *</b></h5>
                                {bulletPoints?.map((item, index) => (
                                    <Col md={12}>
                                        <div className='mb-4'>
                                            <AvField
                                                name={"point" + index}
                                                label={`Point ${index + 1} *`}
                                                placeholder={"Enter bullet point " + (index + 1)}
                                                value={item}
                                                required
                                                onChange={(e) => handleBulletInput(e.target.value, index)}
                                            />
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                            {/* <div className='divider' /> */}
                            <Row>
                                <Col md={4} />
                                <Col md={2}>
                                    <Button type="reset" onClick={() => setIsAdd(false)} className="btn-sign w-100">Cancel</Button>
                                </Col>
                                <Col md={2}>
                                    <Button type="submit" className="btn-sign w-100">{currentData?._id ? 'Update' : 'Add'}</Button>
                                </Col>
                                <Col md={4} />
                            </Row>
                        </AvForm>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    )
}

export default AddForm;